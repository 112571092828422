/**
 * @file Displays an error in the notification popup, and logs the error
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Logger from 'abb-webcore-logger/Logger';
import { NotificationContent } from 'webcore-ux/react/components';

type ErrorNotificationProps = {
    errorPrefix: string;
    unknownErrorMessage: string;
    error: string | Error;
};

export const ErrorNotification = ({ errorPrefix, unknownErrorMessage, error }: ErrorNotificationProps): JSX.Element => {
    let msg;

    if (typeof error === 'string') {
        msg = error;
    } else if (error && error.message) {
        msg = error.message;
    } else if (error && error.toString) {
        msg = error.toString();
    } else {
        msg = unknownErrorMessage;
    }

    Logger.error(msg);

    // Render the error
    return (
        <div className={'abbes-landing-error-notification'} data-testid="abbes-landing-error-notification">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*// @ts-ignore*/}
            <NotificationContent onClose={() => null} message={`${errorPrefix} ${msg}`} severity="error" variant="confirmation" />
        </div>
    );
};

ErrorNotification.defaultProps = {
    errorPrefix: 'An error has occurred:',
    unknownErrorMessage: 'Error message not available',
};

ErrorNotification.propTypes = {
    error: PropTypes.oneOfType([
        PropTypes.shape({
            message: PropTypes.string,
            toString: PropTypes.func,
        }),
        PropTypes.string,
    ]).isRequired,
    errorPrefix: PropTypes.string,
    unknownErrorMessage: PropTypes.string,
};

export default ErrorNotification;
