/**
 * @file Utility functions relating to i18n
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import i18next from 'i18next';
import * as Settings from '../Settings';
import {
    commonNamespace,
    landingConfigNamespace,
    deDeimListNamespace,
    listViewConfigNamespace,
    deDeimDomainNamespace,
    deWorkorderNamespace,
} from './constants';
import locales from './locales.json';
import { setupI18next, reloadBackendLocales } from 'webcore-common/LocaleLoader';

const namespaces = [
    commonNamespace,
    landingConfigNamespace,
    deDeimListNamespace,
    listViewConfigNamespace,
    deDeimDomainNamespace,
    deWorkorderNamespace,
];

let setup = false;

/**
 * sets up the locales
 * @param {function} getToken - the keycloak getToken function
 * @returns {Promise<any>} the setup as a promise
 */
function setupLocales(getToken: () => void): Promise<unknown> {
    if (!setup) {
        setup = true;
        const options = {
            urlPrefix: Settings.getEamxHost(),
            locales,
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return setupI18next(i18next, getToken, namespaces, options);
    } else {
        return Promise.resolve();
    }
}

/**
 * loads the locales from backend
 * @returns {Promise<any>} the locales as a promise
 */
function loadBackendLocales(): Promise<unknown> {
    return reloadBackendLocales(i18next, null, namespaces);
}

export { setupLocales, loadBackendLocales };
