/**
 * @file Config Loader for the Landing App
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import * as Settings from './Settings';
import { loadConfigV1 } from 'webcore-common';

/**
 * Loads the configuration
 * @param {Function} getToken - the getToken function from keycloak
 * @return {Promise} - Returns a Promise from loading the config
 */
export function loadConfiguration(getToken: Function): Promise<unknown> {
    const options = {
        urlPrefix: Settings.getEamxHost(),
        getToken,
        defaultConfig: {},
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return loadConfigV1('de-landing-config', options);
}

/**
 * Returns a function that returns a Promise for the appInsights key configuration
 * @param {Function} getToken - the getToken function from keycloak
 * @return {function(): Promise} - Returns a function that returns the appInsights key object in a Promise once evaluated
 */
export function loadAppInsightsConfig(getToken: Function) {
    const baseOptions = { urlPrefix: Settings.getEamxHost() || '', getToken };

    const getKeyConfigPromise = () =>
        loadConfigV1('de-keys-config', {
            ...baseOptions,
            outputParser: (config: any) => config,
            defaultConfig: {},
        });

    return getKeyConfigPromise().then((keyConfig) => {
        return {
            keyConfig,
        };
    });
}
