/**
 * @file Contains access functions for getting data from the settings.json file,
 * saving and getting data from local storage
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import loader from './SettingsLoader';
import Logger from 'abb-webcore-logger/Logger';
import { AppSettings } from './types';
import { LANDING_LINK } from './components/_constants';
import i18next from 'i18next';
import { commonNamespace } from './locales/constants';

const localStorage = window.localStorage,
    defaultConfig: AppSettings = {
        version: 'development',
        eamxHost: 'https://de-smoketest4.digital.enterprisesoftware.abb',
        helpUrl: '',
    };

let settingsJSON: AppSettings;

/**
 * Loads the settings passed in, reverts to the default settings on a failure.
 *
 * @private
 * @param {object|string} settings - The settings to attempt to load
 * @return {Promise} - Resolves when the settings are loaded
 */
function loadSettings(settings: object | string): Promise<null> {
    return new Promise((resolve) => {
        try {
            Logger.debug(`updating settingsJSON with ${settings}`);

            settingsJSON = parseRespSettings(settings);
            resolve();
        } catch (error) {
            Logger.error('Failed to load config--using default.  Details: ' + error);
            settingsJSON = defaultConfig;
            resolve();
        }
    });
}

/**
 * Parses the response into an object, if it's not already an object and returns it.
 *
 * @param {object|string} settings - the settings to parse
 * @return {object} jsonSettings that were parsed
 */
function parseRespSettings(settings: object | string) {
    let jsonSettings;

    if (typeof settings === 'string') {
        jsonSettings = JSON.parse(settings);
    } else if (typeof settings === 'object') {
        jsonSettings = settings;
    } else {
        throw new Error(`Invalid settings type: ${typeof settings}, ${settings}`);
    }

    return jsonSettings;
}

/**
 * Runs the loader and loads the settings, returning a promise
 *
 * @returns {Object} promise that handles the dynamic loading of the settings
 */
async function loadBrowserWebSettings(): Promise<unknown> {
    const settings = await loader();
    return loadSettings(settings as AppSettings);
}

/**
 * Returns the current version of the app, as defined in settings.json
 *
 * @returns {string} The version of the application
 */
function getVersion(): string | undefined {
    return settingsJSON.version;
}

/**
 * Gets the EAMX host (e.g. https://eamx.azurefd.net)
 *
 * @returns {string} The host URL
 */
function getEamxHost(): string | undefined {
    return settingsJSON.eamxHost;
}

/**
 * Sets the EAMX host (e.g. https://eamx.azurefd.net) and store it locally for mobile app usage
 *
 * @param {string} host - The host URL
 */
function setEamxHost(host: string): void {
    settingsJSON.eamxHost = host;
    localStorage.setItem('eamxHost', host);
}

/**
 * Gets the release version
 *
 * @param {string} version - The app version
 * Currently, there is no need to display the release number on UI. Therefore, the function is returning "undefined".
 */

function getReleaseNumber(): string | undefined {
    return undefined;
}

/**
 * Get the host information from localStorage (for mobile app)
 *
 * @returns {string } null host information
 */
function getLocalEamxHost(): string | null {
    return localStorage.getItem('eamxHost');
}

/**
 * Returns the help url for DE, this is currently a simple non-localized help link at this time.  This will need to be
 * expanded when we support localized/internationalized help.
 *
 * @returns {string} - the URL as a string
 */
function getHelpUrl(): string | undefined {
    return settingsJSON.helpUrl;
}

/**
 * gets the translated help text for menu
 *
 * @returns {string} the translated help text
 */
function getHelpText(): string {
    return i18next.t(`${commonNamespace}:mainMenu.helpMenuItem`) || 'Help';
}

/**
 * Get the landing link string
 *
 * @returns {string} the landing link
 */
function getLandingLink(): string {
    return LANDING_LINK;
}

/**
 * Get the landing link string
 *
 * @returns {string} the landing link
 */
function getHomepageLayoutConfigId(): string {
    return 'lumada-landing-homepageView-config';
}

export {
    getVersion,
    loadBrowserWebSettings,
    setEamxHost,
    getEamxHost,
    getLocalEamxHost,
    getHelpUrl,
    getLandingLink,
    getHelpText,
    getHomepageLayoutConfigId,
    getReleaseNumber,
};
