/**
 * @file Constants relating to i18n
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

export const commonNamespace = 'app-common';
export const landingConfigNamespace = 'app-landing-configuration';
export const listViewCommonNamespace = 'app-list-view-common';
export const listViewConfigNamespace = 'app-list-view-configuration';
export const deDeimDomainNamespace = 'de-deim-domain';
export const deDeimListNamespace = 'de-deim-lists';
export const deWorkorderNamespace = 'de-workorder';
