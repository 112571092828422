/**
 * @file redux authentication reducer
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import * as t from '../../types';
import { ActionParams, AuthenticationState } from '../../types';

/**
 * the authentication state
 * @returns {object} the initialized state
 */
export const State = (): AuthenticationState => ({
    isInitialized: false,
    isUserSignedOn: false,
    realmAccessRoles: [],
    keycloak: null,
    signOnInProgress: false,
});

/**
 * the authentication reducer
 * @param {AuthenticationState} state - the authentication state
 * @param {ActionParams.type} type - the action type
 * @param {ActionParams.payload} payload - the action payload
 * @returns {AuthenticationState} the current state
 */
export const authentication = (state = State(), { type, payload }: ActionParams): AuthenticationState => {
    switch (type) {
        case t.AUTHENTICATION_UPDATE:
            return { ...state, ...payload };
        case t.AUTHENTICATION_RESET:
            return State();
        default:
            return state;
    }
};
