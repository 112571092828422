/**
 * @file Route for invalid route conditions, invalid url, page not found etc
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import { commonNamespace } from '../locales/constants';

type InvalidRouterProps = {
    title: string;
    description: string;
};

type InvalidRouterState = {
    countdown: number;
};

class InvalidRouter extends React.Component<InvalidRouterProps, InvalidRouterState> {
    timerID = 0;

    constructor(props: InvalidRouterProps) {
        super(props);

        this.state = {
            countdown: 1,
        };
    }

    componentDidMount(): void {
        this.timerID = window.setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount(): void {
        if (this.timerID) {
            window.clearInterval(this.timerID);
        }
    }

    tick(): void {
        this.setState({
            countdown: this.state.countdown - 1,
        });
    }

    render(): JSX.Element {
        const title = this.props.title || i18next.t(`${commonNamespace}:invalidRouter.defaultTitle`),
            description = this.props.description || i18next.t(`${commonNamespace}:invalidRouter.defaultDescription`);

        // If we hit 0, redirect to the main page
        if (this.state.countdown === 0) {
            return (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Redirect
                    to={{
                        pathname: '/',
                    }}
                />
            );
        }

        return (
            <div data-testid={'invalid-router'}>
                <h3>{title}</h3>
                <p>{description}</p>
                <br />
                <p>{i18next.t(`${commonNamespace}:invalidRouter.countDownText`, { time: this.state.countdown })}</p>
            </div>
        );
    }
}

export default InvalidRouter;
