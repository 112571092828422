/**
 * @file Contains functions for sanitizing state and action objects so that it can be serialized
 * @copyright © Copyright 2022 Hitachi Energy. All rights reserved.
 */

import { ActionParams, CombinedState } from './redux/types';

/**
 * Sanitize the state so that it can be serialized
 *
 * Any large data sets should be removed in here, there's not enough room in insights to log them over and over.
 *
 * Note: This affects only the previousState and nextState logs, the action where the value is set logs unless listed
 * as skipped in the actionSanitizer function below.
 *
 * @param {object} state - Redux state
 * @return {object} sanitized state
 */
function stateSanitizer(state: CombinedState): CombinedState {
    const sanitized = JSON.parse(JSON.stringify(state)); // make a deep copy

    if (state.authentication?.keycloak) {
        sanitized.authentication.keycloak = '<Keycloak object>';
    }

    return sanitized;
}

/**
 * Sanitize the action so that it can be serialized
 * @param {object} action - Redux action
 * @return {object} sanitized action
 */
function actionSanitizer(action: ActionParams): ActionParams {
    const sanitized = JSON.parse(JSON.stringify(action)); // make a deep copy

    if (action.payload?.keycloak) {
        sanitized.payload.keycloak = '<Keycloak object>';
    }

    return sanitized;
}

export { stateSanitizer, actionSanitizer };
