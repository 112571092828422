/**
 * @file redux types
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import Keycloak from 'keycloak-js';

export type ActionParams = {
    type: string;
    payload: any;
};

export type CombinedState = {
    app: AppState;
    appLaunchers: AppLaunchersState;
    authentication: AuthenticationState;
    currentUser: CurrentUserState;
};
export type AppState = {
    initializeState: string;
    initializeError: string | Error | undefined;
};
export type AppLaunchersState = {
    config: unknown;
};
export type AuthenticationState = {
    isInitialized: boolean;
    isUserSignedOn: boolean;
    signOnInProgress: boolean;
    realmAccessRoles: string[];
    keycloak: Keycloak.KeycloakInstance | null;
};
export type CurrentUserState = {
    name: string;
    email: string;
    initials: string;
};

export const APP_UPDATE = 'APP_UPDATE';
export const APP_RESET = 'APP_RESET';
export const APP_INIT = 'APP_INIT';
export const APP_INIT_START = 'APP_INIT_START';
export const APP_INIT_COMPLETE = 'APP_INIT_COMPLETE';
export const APP_INIT_ERROR = 'APP_INIT_ERROR';

export const AUTHENTICATION_UPDATE = 'AUTHENTICATION_UPDATE';
export const AUTHENTICATION_RESET = 'AUTHENTICATION_RESET';
export const AUTHENTICATION_INIT = 'AUTHENTICATION_INIT';
export const AUTHENTICATION_SIGN_ON = 'AUTHENTICATION_SIGN_ON';
export const AUTHENTICATION_SIGN_OFF = 'AUTHENTICATION_SIGN_OFF';
export const AUTHENTICATION_INITIALIZE_KEYCLOAK = 'AUTHENTICATION_INITIALIZE_KEYCLOAK';

export const CURRENT_USER_RESET = 'CURRENT_USER_RESET';
export const CURRENT_USER_UPDATE = 'CURRENT_USER_UPDATE';

export const APP_LAUNCHERS_RESET = 'APP_LAUNCHERS_RESET';
export const APP_LAUNCHERS_UPDATE = 'APP_LAUNCHERS_UPDATE';
