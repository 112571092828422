/**
 * @file app
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useEffect } from 'react';
import AppWrapperContainer from 'de-common-client/nextgen/components/AppWrapper/AppWrapperContainer';
import { getUserAuthorizationData } from 'de-common-client/api/AuthorizationAPI';
import { LandingContainer } from './containers';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './redux';
import i18next from 'i18next';
import ErrorNotification from './components/ErrorNotification/ErrorNotification';
import { commonNamespace } from './locales/constants';
import { LoadMask } from 'de-common-client/nextgen/components';
import classNames from 'classnames';
import * as t from './redux/types';
import { getCombinedState, signOff } from './redux/actions';
import thirdPartyJson from './thirdParty.json';
import { getToken } from './redux/sagas/app';
import * as Settings from './Settings';
import ThemeProvider from 'webcore-ux/nextgen/theme/ThemeProvider';

/**
 * creates the react app
 * @constructor
 */
function App(): JSX.Element {
    const dispatch = useDispatch();

    const { app, currentUser, authentication } = useSelector(getCombinedState);

    /**
     * fires on the click of the signOff link in profile menu
     */
    const onClickSignOff = () => {
        dispatch(signOff(`${window.location.protocol}//${window.location.host}/${Settings.getLandingLink()}`));
    };

    useEffect(() => {
        dispatch(actions.update({ type: t.APP_INIT, payload: {} }));
        return (): any => dispatch(actions.update({ type: t.APP_RESET, payload: {} }));
    }, []);

    if (app.initializeState === t.APP_INIT_COMPLETE) {
        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <AppWrapperContainer
                data-testid={'app-wrapper-container'}
                appName={'Landing'}
                activatedApp={'homeapp'}
                settings={Settings}
                currentUser={currentUser}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                getPermissionsData={() => getUserAuthorizationData(Settings.getEamxHost(), getToken(authentication))}
                signOff={onClickSignOff}
                thirdPartyJson={thirdPartyJson}
                getToken={getToken(authentication)}
            >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*// @ts-ignore*/}
                <LandingContainer className={classNames('de-landing-landing-container')} />
            </AppWrapperContainer>
        );
    } else if (app.initializeState === t.APP_INIT_ERROR) {
        return (
            <ErrorNotification
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                error={app.initializeError}
                errorPrefix={i18next.t(`${commonNamespace}:app.errorNotificationPrefix`)}
                unknownErrorMessage={i18next.t(`${commonNamespace}:app.errorNotificationUnknownError`)}
            />
        );
    } else {
        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <ThemeProvider>
                <LoadMask />
            </ThemeProvider>
        );
    }
}

export default App;
