/**
 * @file WidgetEditor component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { ReactElement, Fragment, useState } from 'react';
import { Button } from '@material-ui/core';
import classNames from 'classnames';

import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { SimpleTray } from 'webcore-ux/nextgen/components/SimpleTray/SimpleTray';
import { default as SimpleTrayContext } from 'webcore-ux/nextgen/components/SimpleTray/SimpleTrayContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {},
    actionBar: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    content: {
        textAlign: 'left',
        margin: theme.spacing(1),
    },
}));

/**
 * Widget Editor component
 * @returns {JSX.Element} - The component to render
 */
export default function WidgetEditor(): ReactElement {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [open, setOpen] = useState(false);

    /**
     * toggle the tray open/closed
     * @param {boolean} open the open state, true for open
     */
    const toggleTray = (open: boolean) => {
        setOpen(open);
    };

    return (
        <Fragment>
            <Button startIcon={<AddBoxOutlinedIcon />} onClick={() => toggleTray(!open)} data-testid={'add-widget-button'}>
                Add Widget
            </Button>

            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <SimpleTrayContext.Provider value={{ open, setOpen }}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <SimpleTray
                    anchor={'right'}
                    hideBackdrop={true}
                    onClose={() => {
                        toggleTray(false);
                    }}
                    className={classNames('abbes-landing-page-edit-widget-tray')}
                >
                    <div
                        className={classNames('abbes-landing-page-edit-widget-container', classes.container)}
                        data-testid={'edit-widget-container'}
                    >
                        <div
                            className={classNames('abbes-landing-page-edit-widget-container-actions', classes.actionBar)}
                            data-testid={'edit-widget-container-actions'}
                        >
                            {/*<Button startIcon={<AddBoxOutlinedIcon />} data-testid={'add-widget-button'}>Add Widget</Button>*/}
                        </div>
                        <div
                            className={classNames('abbes-landing-page-edit-widget-container-content', classes.content)}
                            data-testid={'edit-widget-container-content'}
                        >
                            [editor content]
                        </div>
                    </div>
                </SimpleTray>
            </SimpleTrayContext.Provider>
        </Fragment>
    );
}
