/**
 * @file Redux reducer functions
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import { combineReducers } from 'redux';
import { app, State as AppState } from './app';
import { authentication, State as AuthenticationState } from './authentication';
import { currentUser, State as CurrentUserState } from './current-user';

export const combinedState = {
    app: AppState(),
    currentUser: CurrentUserState(),
    authentication: AuthenticationState(),
};

export const reducers = combineReducers({
    app,
    currentUser,
    authentication,
});
