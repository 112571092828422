/**
 * @file redux store
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import { applyMiddleware, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createCustomReduxLogger } from 'webcore-common/redux-logger';
import Logger from 'abb-webcore-logger/Logger';
import * as Sanitizer from '../Sanitizer';
import { reducers } from './reducers';
import { createLogger, default as ReduxLogger } from 'redux-logger';
import { sagas } from './sagas';

/**
 * Creates the redux store and sets up the middleware/loggers etc
 *
 * @returns {object} store - The Redux Store
 */
export function setupRedux(): Store {
    const sagaMiddleware = createSagaMiddleware();

    // Setup Redux dev tools
    const composeEnhancers = composeWithDevTools({
        // Add state sanitizer, otherwise Redux dev tools will crash.
        // stateSanitizer: Sanitizer.stateSanitizer,
    });

    // Setup custom Redux logger
    const customReduxLogger = createCustomReduxLogger(Logger, Sanitizer.stateSanitizer, Sanitizer.actionSanitizer);

    // Setup Redux store
    // NOTE: Order matters and ReduxLogger should be last.
    const store = createStore(
        reducers,
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                customReduxLogger as never,
                createLogger({ stateTransformer: Sanitizer.stateSanitizer, actionTransformer: Sanitizer.actionSanitizer })
            )
        )
    );

    sagaMiddleware.run(sagas);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return store;
}
